/* eslint-disable prefer-destructuring, sort-keys */

import { darken, lighten, linearGradient, mix } from 'polished';

/* base colors */

const blue = '#0663B3';
const blueDark = '#0B2238';
const blueLight = '#2695F8';
const blueLightSecondary = 'rgba(87,173,250,0.2)';
const green = '#3AC4AC';
const greenDark = '#43A047';
const greenLight = '#7cb342';
const limeYellow = '#fdd835';
const amberOrange = '#f57c00';
const darkenOrange = '#EC7B41';
const orange = '#F19B6F';
const red = '#E74C3C';
const whiteMoreTransparent = 'rgba(255, 255, 255, 0.1)';
const whiteTransparent = 'rgba(255, 255, 255, 0.4)';
const transparent = 'rgba(0, 0, 0, 0)';

const white = '#fefefe';
const gray1 = '#F5F6FA';
const gray2 = '#EBEEF5';
const gray3 = '#DFE4F0';
const gray4 = '#BDC6DF';
const gray5 = '#8190AD';
const gray6 = '#2e2e31';
const black = '#0B2238';
const purple = '#AF66CF';
const yellow = '#EFCF6B';
/* theme colors */

const primary = blueDark;
const primaryDark = darken(0.1, blueDark);
const primaryLight = lighten(0.1, blueDark);

const accent = blueLight;
const accentDark = darken(0.25, blueLight);
const accentLight = lighten(0.1, blueLight);
const accentExtraLight = mix(0.85, '#ffffff', blueLight);
const errorExtraLight = mix(0.85, '#ffffff', red);

const accentSecondary = blue;
const accentSecondaryDark = darken(0.1, blue);
const accentSecondaryLight = lighten(0.1, blue);

const textHeading = blueDark;
const textLink = accent;
const textPrimary = lighten(0.05, blueDark);
const textSubtle = gray5;
const textSubtleSecondary = whiteTransparent;

const colors = {
  accent,
  accentDark,
  accentLight,
  accentExtraLight,
  accentSecondary,
  accentSecondaryDark,
  accentSecondaryLight,
  amberOrange,
  black,
  body: gray2,
  border: gray4,
  borderSecondary: whiteMoreTransparent,
  blueLightSecondary,
  darkenOrange,
  error: red,
  errorExtraLight,
  grays: [gray1, gray2, gray3, gray4, gray5, gray6],
  greenDark,
  greenLight,
  limeYellow,
  primary,
  primaryDark,
  primaryLight,
  success: green,
  text: {
    heading: textHeading,
    link: textLink,
    primary: textPrimary,
    subtle: textSubtle,
    subtleSecondary: textSubtleSecondary,
  },
  visualizations: [
    blueLight,
    green,
    orange,
    red,
    yellow,
    purple,
    darken(0.1, blueLight),
    lighten(0.2, green),
    darken(0.1, orange),
    lighten(0.1, red),
    darken(0.1, yellow),
    lighten(0.1, purple),
    lighten(0.1, blueLight),
    darken(0.1, green),
    lighten(0.1, orange),
    darken(0.1, red),
    lighten(0.1, yellow),
    darken(0.1, purple),
  ],
  warning: orange,
  white,
  transparent,

  // TODO: remove these
  gray1,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  textHeading,
  textLink,
  textPrimary,
  textSubtle,
  textSubtleSecondary,
};

/* Gradient */

/* base gradients */
const baseGradients = {
  accentDark: linearGradient({
    colorStops: [
      `${lighten(0.05, accentDark)} 0%`,
      `${darken(0.05, accentDark)} 100%`,
    ],
    fallback: accentDark,
    toDirection: 'to bottom',
  }),
  white: linearGradient({
    colorStops: [`${darken(0.05, white)} 0%`, `${lighten(0.1, white)} 100%`],
    fallback: white,
    toDirection: 'to bottom',
  }),
};

/* theme gradients */
const gradients = {
  sidebar: baseGradients.accentDark,
  header: baseGradients.white,
};

/* breakpoints */

const breakpoints = ['300px', '500px', '700px', '1200px', '1600px'];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

/* typography */

const baseFontSizesForBreakpoint = ['3.5vw', '3.5vw', '2.2vw', '15px', '15px'];

const fonts = {
  mono: 'Menlo, monospace',
  primary: 'Montserrat, sans-serif',
  secondary: 'Montserrat, sans-serif',
};

const fontSizeScale = 1.25;

const fontSizes = [
  `${1 / fontSizeScale / fontSizeScale / fontSizeScale}rem`,
  `${1 / fontSizeScale / fontSizeScale}rem`,
  `${1 / fontSizeScale}rem`,
  '1rem',
  `${fontSizeScale}rem`,
  `${fontSizeScale * fontSizeScale}rem`,
  `${fontSizeScale * fontSizeScale * fontSizeScale}rem`,
  `${fontSizeScale * fontSizeScale * fontSizeScale * fontSizeScale}rem`,
];

// TODO: remove these
fontSizes.xxs = fontSizes[0];
fontSizes.xs = fontSizes[1];
fontSizes.sm = fontSizes[2];
fontSizes.md = fontSizes[3];
fontSizes.lg = fontSizes[4];
fontSizes.xl = fontSizes[5];
fontSizes.xxl = fontSizes[6];
fontSizes.xxxl = fontSizes[7];

const fontWeights = {
  bold: 600,
  light: 300,
  normal: 500,
};

const letterSpacings = ['0.05em', '0.1em', '0.125em'];

// TODO: remove these
letterSpacings.sm = letterSpacings[0];
letterSpacings.md = letterSpacings[1];
letterSpacings.lg = letterSpacings[2];

const lineHeights = ['1.2em', '1.4em', '1.6em'];

// TODO: remove these
lineHeights.sm = lineHeights[0];
lineHeights.md = lineHeights[1];
lineHeights.lg = lineHeights[2];

/* space */

const space = [
  '0',
  '0.25rem',
  '0.375rem',
  '0.5rem',
  '0.75rem',
  '1.25rem',
  '2rem',
  '3.25rem',
  '5.25rem',
  '8.5rem',
];

// TODO: remove these
space.xxxs = space[1];
space.xxs = space[2];
space.xs = space[3];
space.sm = space[4];
space.md = space[5];
space.lg = space[6];
space.xl = space[7];
space.xxl = space[8];
space.xxxl = space[9];

/* sizes */

const sizes = {
  heights: {
    header: '5rem',
    input: `calc(${lineHeights[2]} + ${space[4]} * 2)`,
  },
  maxWidths: {
    content: '1600px',
    form: '500px',
    largeForm: '800px',
    smallForm: '380px',
  },
  widths: { sidebar: '272px', submitButton: '14rem' },
};

/* border radii */

const radii = ['2px', '5px', '8px'];

// TODO: remove these
radii.sm = radii[0];
radii.md = radii[1];
radii.lg = radii[2];

/* box shadows */

const shadows = [
  '0 3px 10px 0 rgba(164, 174, 185, 0.1)',
  '0 10px 25px rgba(164, 174, 185, 0.2)',
  '0 20px 50px rgba(11, 34, 56, 0.3)',
  '0 20px 60px rgba(0, 0, 0, 0.4)',
];

/* z indexes */

const zIndices = [1, 2, 3, 4, 5];

/* variants */

const button = {
  '&:hover': { bg: 'accentDark' },
  bg: 'accent',
  borderColor: 'transparent',
  borderRadius: 1,
  borderStyle: 'solid',
  borderWidth: '1px',
  boxShadow: 1,
  color: 'white',
  fontSize: 2,
  fontWeight: 'bold',
  height: 'heights.input',
  letterSpacing: 0,
  px: 7,
  py: 4,
  textTransform: 'uppercase',
  transition: 'background-color 0.2s, border-color 0.2s, color 0.2s',
};

const transparentButton = {
  ...button,
  '&:hover': { bg: 'primary', borderColor: 'primary', color: 'white' },
  bg: 'inherit',
  borderColor: 'textSubtle',
  color: 'primary',
};

const variants = {
  buttons: {
    cancel: {
      ...transparentButton,
      '&:hover': { bg: 'error', borderColor: 'error', color: 'white' },
      borderColor: 'border',
      color: 'border',
    },
    accentCancel: {
      ...transparentButton,
      '&:hover': { bg: 'error', borderColor: 'error', color: 'white' },
      borderColor: 'accent',
      color: 'accent',
    },
    link: {
      '&:hover': { textDecoration: 'underline' },
      py: 2,
      textAlign: 'left',
      textDecoration: 'none',
    },
    primary: { ...button },
    secondary: {
      ...button,
      '&:hover': { bg: 'accentSecondaryDark' },
      bg: 'accentSecondary',
    },
    tertiary: { ...button, '&:hover': { bg: 'primaryDark' }, bg: 'primary' },
    transparent: transparentButton,
  },
  inputs: {
    primary: {
      '&::placeholder': { color: 'grays.3' },
      '&:focus': { borderColor: 'primary' },
      bg: 'white',
      borderColor: 'grays.2',
      borderRadius: 1,
      borderStyle: 'solid',
      borderWidth: '1px',
      color: 'text.primary',
      px: 4,
      py: 4,
    },
  },
};

/* theme */

export default {
  baseFontSizesForBreakpoint,
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  gradients,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  sizes,
  space,
  variants,
  zIndices,
};
